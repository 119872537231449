import { mapActions, mapGetters, mapMutations } from 'vuex'
import { debounce } from 'lodash'

import mixins from '@/mixins'

import CountryFlag from 'vue-country-flag'

import { eventStatuses, eventTableHead } from '@/modules/events/heplers'

export default {
  name: 'events-main',
  mixin: [mixins],
  data () {
    return {
      page: 1,
      showRemoveDialog: false,
      removingItem: null
    }
  },
  components: {
    CountryFlag
  },
  computed: {
    ...mapGetters({
      countries: 'setting/countries',
      list: 'events/list',
      isListLoading: 'events/isListLoading',
      lengthList: 'events/listLength',
      removeListItemLoading: 'events/removeListItemLoading',
      skip: 'events/skip',
      limit: 'events/limit',
      accessCountries: 'profile/accessCountries',
      filterKeywords: 'events/filterKeywords',
      filterCountries: 'events/filterCountries',
      filterStatus: 'events/filterStatus'
    }),
    eventTableHead,
    eventStatuses
  },
  created () {
    this.fetchList()
    if (!this.countries) {
      this.fetchCountries()
    }
    if (this.list) {
      if (this.skip !== 0) {
        this.page = Math.round(this.skip / this.limit) + 1
      }
    }
  },
  methods: {
    ...mapActions({
      fetchList: 'events/EVENTS_FETCH_LIST',
      remove: 'events/EVENTS_LIST_DELETE_ITEM',
      fetchCountries: 'setting/GET_COUNTRIES'
    }),
    ...mapMutations({
      changeFilter: 'events/EVENTS_CHANGE_FILTER',
      changeSkip: 'events/EVENTS_LIST_CHANGE_SKIP'
    }),
    changePagination (index) {
      this.changeSkip(this.limit * (index - 1))
      this.$vuetify.goTo(0)
      this.fetchList()
    },
    changeDialogDeleteItem (item) {
      this.showRemoveDialog = true
      this.removingItem = item
    },
    removeListItem () {
      this.remove(this.removingItem).then(() => {
        this.showRemoveDialog = false
        this.$toasted.success(this.$t('success_deleted'))
      })
    },
    changeFilterLocal (value, type) {
      const obj = {
        type: type,
        value: value
      }
      this.changeFilter(obj)
      this.changeSkip(0)
      this.sendRequest()
      
      this.page = 1
    },
    sendRequest: debounce(function () {
      this.fetchList()
    }, 1000)
  }
}
